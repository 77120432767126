import { Col, Container, Row } from "react-bootstrap";
import Header from "../Hub/components/Header";
import { HubContainer } from "../Hub/Hub.styles";
import PomodoroTimer from "./components/PomodoroTimer";

const Productivity = () => {
  
    return (
        <HubContainer>
            <Header />


            <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={5} lg={4}>
            <PomodoroTimer />
          </Col>
          </Row>
          </Container>


        </HubContainer>
    );
  };
  
  export default Productivity;