import { Button, Form } from 'react-bootstrap';
import { DarkModal } from '../../../styles/DarkModal.styles';
import ReactSlider from 'rc-slider';
import 'rc-slider/assets/index.css';
import styled from 'styled-components';
import { palette } from "../../../styles/palette";

interface SettingsModalProps {
  show: boolean;
  onHide: () => void;
  timerMinutes: number;
  setTimerMinutes: (value: number) => void;
}

const SliderWrapper = styled.div`
  margin-top: 15px;
`;

const PomodoroSettingsModal = ({ show, onHide, timerMinutes, setTimerMinutes }: SettingsModalProps): JSX.Element => (
  <DarkModal show={show} onHide={onHide} customSize="md">
    <DarkModal.Header closeButton>
      <DarkModal.Title>Pomodoro Settings</DarkModal.Title>
    </DarkModal.Header>
    <DarkModal.Body>
      <Form>
        <Form.Group>
          <Form.Label>Timer Duration: {timerMinutes}:00</Form.Label>
          <SliderWrapper>
            <ReactSlider
              min={1}
              max={120}
              step={1}
              value={timerMinutes}
              onChange={(value) => setTimerMinutes(value as number)}
              styles={{
                track: { backgroundColor: palette.aqua, height: 6 },
                handle: { borderColor: palette.aqua, backgroundColor: palette.grey },
                rail: { backgroundColor: '#ccc', height: 6 }
              }}
            />
          </SliderWrapper>
        </Form.Group>
      </Form>
    </DarkModal.Body>
    <DarkModal.Footer>
      <Button variant="secondary" onClick={onHide}>Close</Button>
    </DarkModal.Footer>
  </DarkModal>
);

export default PomodoroSettingsModal;
