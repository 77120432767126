import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Row, Col, Card } from 'react-bootstrap';
import styled from 'styled-components';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import HistoryIcon from '@mui/icons-material/History';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import PomodoroSettingsModal from './PomodoroSettingsModal';
import { palette } from "../../../styles/palette";

const TimerContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TimerCard = styled.div`
    margin: 10px 0;
    padding: 20px;
    border-radius: 25px;
    background: #1c1c1c;  
    border: none;

    /* From https://css.glass */
    background: rgba(0, 0, 0, 0.14);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(9.5px);
    -webkit-backdrop-filter: blur(9.5px);
    border: 1px solid rgba(0, 0, 0, 0.16);

    box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
    -webkit-box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
    -moz-box-shadow: 0px 0px 60px 10px rgba(39,39,39,0.6) inset;
`;


const CircleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 300px;
  height: 300px;
  position: relative;
`;

const StyledCardTitle = styled(Card.Title)`
    color: ${palette.white}
`;

const PomodoroTimer: React.FC = () => {
  const [timerMinutes, setTimerMinutes] = useState<number>(25);
  const [timeLeft, setTimeLeft] = useState<number>(timerMinutes * 60);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);

  const timeLeftRef = useRef(timeLeft);
  const isActiveRef = useRef(isActive);

  useEffect(() => {
    timeLeftRef.current = timerMinutes * 60;
    setTimeLeft(timeLeftRef.current);
  }, [timerMinutes]);

  function tick() {
    timeLeftRef.current--;
    setTimeLeft(timeLeftRef.current);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (isActiveRef.current && timeLeftRef.current > 0) {
        tick();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const handleStartPause = () => {
    setIsActive(!isActive);
    isActiveRef.current = !isActive;
  };

  const handleReset = () => {
    setIsActive(false);
    setTimeLeft(timerMinutes * 60);
    isActiveRef.current = false;
    timeLeftRef.current = timerMinutes * 60;
  };

  const percentage = (timeLeft / (timerMinutes * 60)) * 100;
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds.toString();

  return (
    <TimerContainer>
      <TimerCard>
        <Card.Body>
          <StyledCardTitle>Pomodoro</StyledCardTitle>
          <CircleWrapper>
            <CircularProgressbar
              value={percentage}
              text={`${minutes}:${formattedSeconds}`}
              styles={buildStyles({
                textSize: '16px',
                textColor: palette.white,
                pathColor: palette.aqua,
                trailColor: palette.grey,
                strokeLinecap: 'butt',
                pathTransition: 'stroke-dashoffset 0.5s ease-in-out',
                pathTransitionDuration: 0.5
              })}
            />
          </CircleWrapper>
          <Row className="justify-content-center mt-3">
            <Col>
              <Button variant={isActive ? 'warning' : 'success'} onClick={handleStartPause}>
                {isActive ? <PauseCircleIcon /> : <PlayCircleIcon />}
              </Button>
            </Col>
            <Col>
              <Button variant="danger" onClick={handleReset}><HistoryIcon /></Button>
            </Col>
            <Col>
              <Button variant="info" onClick={() => setShowSettings(true)}><SettingsIcon /></Button>
            </Col>
          </Row>
        </Card.Body>
      </TimerCard>
      <PomodoroSettingsModal 
        show={showSettings} 
        onHide={() => setShowSettings(false)} 
        timerMinutes={timerMinutes} 
        setTimerMinutes={setTimerMinutes} 
      />
    </TimerContainer>
  );
};

export default PomodoroTimer;
